@import url('https://fonts.googleapis.com/css?family=IBM+Plex+Sans:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i');
html {
  overflow: scroll;
  overflow-x: hidden;
  font-family: 'IBM Plex Sans';
}
li {
  font-weight: bold;
  margin-bottom: 1rem;
  font-size: 5rem;
}

.hello {
  text-align: center;
  width: 100%;
  padding-top: 5rem;
  margin: 0 auto;
  height: 100vh;
  background: lightcoral;
}

::-webkit-scrollbar {
  display: none;
}
::-webkit-scrollbar-thumb {
  background: transparent;
}
a {
  color: #fff;
}

a:visited {
  color: white;
}
